.react-daterange-picker {
    @apply relative inline-flex;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after,
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    @apply box-border;
}
.react-daterange-picker--disabled {
    @apply text-[rgba(0,0,0,0.38)] bg-gray-300;
}
.react-daterange-picker__wrapper {
    @apply rounded border flex font-semibold !text-xs p-1 !border-[rgb(228,228,231)];
}
.react-daterange-picker__wrapper:hover {
    @apply !border-[rgba(0,0,0,0.87)];
}

.react-daterange-picker--open .react-daterange-picker__wrapper {
    @apply !border-[rgb(161,161,170)];
}

.react-daterange-picker__calendar--open {
    @apply !translate-x-[-20%];
}

.react-daterange-picker__button {
    @apply !py-0.5;
}

.react-daterange-picker__button:enabled {
    @apply cursor-pointer;
}

.react-daterange-picker__calendar {
    @apply !w-80;
}

.react-daterange-picker__calendar--closed {
    @apply hidden;
}

.react-daterange-picker__inputGroup {
    @apply !min-w-min;
}

.react-daterange-picker__inputGroup__input {
    @apply !box-content;
}

.react-daterange-picker__range-divider {
    @apply mx-1;
}

.react-calendar {
    @apply rounded shadow-xl !border-0 bg-white !font-Inter !w-80;
}

.react-calendar button {
    @apply m-0 border-0 outline-none;
}
.react-calendar button:enabled:hover {
    @apply cursor-pointer;
}
.react-calendar__navigation {
    @apply flex pl-6 pr-3 py-2 !mb-0;
}

.react-calendar__navigation button:disabled,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    @apply !bg-transparent;
}

.react-calendar__navigation__label {
    @apply order-1 font-medium text-left;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
    @apply text-[rgba(0,0,0,0.54)];
}

.react-calendar__navigation__prev-button {
    @apply order-2;
}

.react-calendar__navigation__next-button {
    @apply order-3;
}

.react-calendar__decade-view,
.react-calendar__year-view,
.react-calendar__month-view {
    @apply !px-[18px] !pb-[18px];
}
.react-calendar__month-view__weekdays {
    @apply text-center uppercase !flex !text-xs !font-normal !text-gray-500 mt-2;
}

.react-calendar .react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__days__day,
.react-calendar__decade-view__years__year,
.react-calendar__year-view__months__month {
    @apply !p-0 !m-0.5 !text-center !text-xs !justify-center !flex-none;
}

.react-calendar .react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__days__day {
    @apply !w-9;
}

.react-calendar__decade-view__years__year {
    @apply !min-w-[64px];
}
.react-calendar__year-view__months__month {
    @apply !min-w-[88px];
}

.react-calendar__month-view__weekdays abbr {
    @apply !no-underline;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    @apply !text-xs flex items-center !font-normal justify-center;
}

.react-calendar__month-view__days {
    @apply mt-2;
}

.react-calendar__month-view__days__day--weekend {
    @apply !text-gray-500;
}

.react-calendar__tile {
    @apply !h-9 !rounded-full;
}
.react-calendar__navigation button:disabled,
.react-calendar__tile:disabled {
    @apply !bg-transparent !text-[rgba(0,0,0,0.25)] !cursor-not-allowed;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    @apply !bg-transparent;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar--selectRange .react-calendar__tile--hover {
    @apply !bg-[rgba(0,0,0,0.04)] !text-[rgba(0,0,0,0.87)];
}

.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
    @apply !bg-black !text-white;
}
