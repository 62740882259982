/** START: GENERAL CK EDITOR CONTENT STYLE */
/*
 * CKEditor 5 (v35.4.0) content styles.
 * Generated on Fri, 13 Jan 2023 13:05:45 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */
:root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-table-caption-background: hsl(0, 0%, 97%);
    --ck-color-table-caption-text: hsl(0, 0%, 20%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
}

.ck-content {
    font-size: .75rem;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: .85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
    display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
    margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
    table-layout: fixed;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
    overflow: hidden;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
    position: relative;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 0.9em auto;
    display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}

.ck-content ol,
.ck-content ul{
    list-style-position: outside;
    margin-left: 1.25rem;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ol {
    list-style-type: decimal !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol {
    list-style-type: lower-latin !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol ol {
    list-style-type: lower-roman !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol ol ol {
    list-style-type: upper-latin !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol ol ol ol {
    list-style-type: upper-roman !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ul {
    list-style-type: disc !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ul ul {
    list-style-type: circle !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ul ul ul {
    list-style-type: square !important;
}
/* ckeditor5-list/theme/liststyles.css */
.ck-content ul ul ul ul {
    list-style-type: square !important;
}

.ck-content h1 {
    font-size: 1rem;
    font-weight: 600;
}

.ck-content h2 {
    font-size: 0.875rem;
    font-weight: 600;
}

.ck-content h3 {
    font-size: 0.75rem;
    font-weight: 600;
}

.ck-content h4 {
    font-size: 0.625rem;
    font-weight: 600;
}
/** END: GENERAL CK EDITOR CONTENT STYLE */

/** START: PDF Creation Overrides */
.populate-pdf-editor {
    height: calc(100vh - 148px);
}

.populate-pdf-editor .ck.ck-editor__editable > .ck-widget.ck-widget_with-selection-handle:first-child {
    margin-top: 0!important;
}

.populate-pdf-ck-content .ck-content {
    scroll-behavior: auto !important;
}

.populate-pdf-ck-content,
.populate-pdf-ck-content .ck-content {
    background-color: transparent !important;
    border-width: 0 !important;
}

.populate-pdf-ck-content .ck-content >  figure.table {
    width: 100% !important;
}

.populate-pdf-ck-content .ck-content >  figure.table table,
.populate-pdf-ck-content .ck-content >  figure.table td {
    border-width: 0 !important;
    vertical-align: top !important;
}

.populate-pdf-ck-content .ck-content > figure.table:first-child tr td:first-child {
    width: 80%;
}

.populate-pdf-ck-content .ck-content > figure.table:first-child tr td:last-child {
    font-size: 0.625rem;
    line-height: 1.25rem;
}

.populate-pdf-ck-content .ck-content > figure.table:first-child tr td:last-child p {
    font-weight: bold !important;
}

.populate-pdf-ck-content .ck-content > figure.table td {
    outline: none !important;
    box-shadow: none !important;
}

.populate-pdf-ck-content .ck.ck-reset_all.ck-widget__type-around,
.populate-pdf-ck-content .ck.ck-widget__selection-handle {
    display: none;
}

.populate-pdf-ck-content .table.ck-widget.ck-widget_with-selection-handle {
    outline: none !important;
}

.populate-pdf-bubble.ck-body-wrapper .ck-toolbar[aria-label="Table toolbar"] {
    display: none !important;
}

.populate-pdf-bubble.ck-body-wrapper .ck-balloon-panel {
    border-width: 0 !important;
    box-shadow: none !important;
}
.populate-pdf-bubble.ck-body-wrapper .ck-toolbar-container:before,
.populate-pdf-bubble.ck-body-wrapper .ck-toolbar-container:after {
    display: none;
}
/** END: PDF Creation Overrides */
